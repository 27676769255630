import sha256 from "crypto-js/sha256";

// SHA256 encoded hashes
const UNLOCK_HASH =
  "bdf49cfcb313227e1cf73c74902f30efd0ea3d5ab372a361af362f8fa94035a1";
const UID_HASH =
  "ba6a37c3a196de3b2cc55da66baad095abf1d8544a32b953ccbe57d128b08b09";

export function validateUID(uid) {
  return sha256(uid).toString() === UID_HASH;
}

export function validateUnlockKey(key) {
  return sha256(key).toString() === UNLOCK_HASH;
}
