import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import { validateUID } from "./validateUser";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    console.log("authConetext initialization");
    return localStorage.getItem("isAuthenticated");
  });
  //   effect for updating localAuthState to new currentUser state
  useEffect(() => {
    console.log("AuthContext useEffect");

    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        if (validateUID(userAuth.uid)) {
          localStorage.setItem("isAuthenticated", true);
          setCurrentUser(userAuth.uid);
          console.log("AuthContext useEffect onAuthChange");
        }
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
