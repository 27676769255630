import React from "react";
import "./reloadBox_styles.scss";
import { MdAutorenew } from "react-icons/md";
import TimeAgo from "timeago-react";

const ReloadBox = ({ loadedFromCache, updatedAt, updateMessages }) => {
  return (
    <div id="relaodBox">
      {/* <p>Last updated : {timeago.format(updatedAt)}</p> */}
      <p>
        Last updated :{" "}
        <TimeAgo live={true} datetime={updatedAt} locale="en_IN" />
        {loadedFromCache && <span>{` (cache)`}</span>}
      </p>

      <MdAutorenew
        onClick={updateMessages}
        size={"1.5em"}
        style={{ verticalAlign: "middle" }}
      />
    </div>
  );
};

export default ReloadBox;
