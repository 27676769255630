import React, { useState } from "react";
import MessageBox from "../../MessageBox/MessageBox";
import { auth, firestore } from "../../../firebase";

import "./homePage_styles.scss";
import ReloadBox from "../../reloadBox/ReloadBox";

function HomePage({ history }) {
  const [updatedAt, setUpdatedAt] = useState(() => {
    return JSON.parse(localStorage.getItem("UPDATED_AT") || Date.now());
  });
  const [loadedFromCache, setLoadedFromCache] = useState(true);

  const [messages, setMessages] = useState(() => {
    return JSON.parse(localStorage.getItem("CACHED_MESSAGES")) || [];
  });

  if (updatedAt <= Date.now() - 30 * 60 * 1000) {
    // fetches if there is no message or its 30 min since last updated.
    console.log("updatating message");
    updateMessages();
  }

  async function updateMessages() {
    console.log("updateMessages");
    try {
      const snapshot = await firestore
        .collection("portfolioMessages")
        .orderBy("createdAt", "desc")
        .get();

      if (!snapshot.metadata.fromCache) {
        setLoadedFromCache(false);
        //  response
        const response = await snapshot.docs.map((doc) => doc.data());
        const currentTime = Date.now();
        // cache update
        localStorage.setItem("CACHED_MESSAGES", JSON.stringify(response));
        localStorage.setItem("UPDATED_AT", currentTime);
        // state update
        setMessages(response);
        setUpdatedAt(currentTime);
      } else {
        setLoadedFromCache(true);
      }
    } catch (err) {
      if (err.code === "permission-denied") {
        signOut();
      }
    }
  }

  function signOut() {
    auth.signOut().then(() => {
      console.log("logged out");
      localStorage.removeItem("CACHED_MESSAGES");
      localStorage.removeItem("UPDATED_AT");
      localStorage.setItem("isAuthenticated", false);
      return history.push("/login");
    });
  }

  return (
    <>
      <main>
        <div className="titleBar">
          <h1 className="pageTitle">Inbox</h1>
          <button
            id="signOutBtn"
            className="button primary-button"
            onClick={signOut}
          >
            Sign Out
          </button>
        </div>
        <div className="messageList">
          <ReloadBox
            loadedFromCache={loadedFromCache}
            updatedAt={updatedAt}
            updateMessages={updateMessages}
          />

          {messages.map((msg) => {
            return (
              <MessageBox
                senderName={msg.name}
                senderEmail={msg.email}
                time={msg.createdAt}
                msg={msg.message}
                status={msg.seen}
                key={msg._id}
              />
            );
          })}
        </div>
      </main>
    </>
  );
}

export default HomePage;
