import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDT7TwxJKfV8d5Q1YMoNvtwwgbT_wUClIY",
  authDomain: "vishalx360dev.firebaseapp.com",
  databaseURL: "https://vishalx360dev.firebaseio.com",
  projectId: "vishalx360dev",
  storageBucket: "vishalx360dev.appspot.com",
  messagingSenderId: "197461407927",
  appId: "1:197461407927:web:d4e20886d75fb808cff532",
  measurementId: "G-DKWWEPFB6R",
};

firebase.initializeApp(config);

export const firestore = firebase.firestore();

firestore.enablePersistence().catch(function (err) {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
  }
});
// Subsequent queries will use persistence, if it was enabled successfully
export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
