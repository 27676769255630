import React, { useState } from "react";

import { MdExpandLess, MdExpandMore } from "react-icons/md";

export default function Turncater({ text, limit }) {
  const [showBtn] = useState(text.length > limit);
  const [isOpened, setIsOpened] = useState(false);

  function toggleOpenState() {
    setIsOpened((previousData) => {
      return !previousData;
    });
  }

  return (
    <>
      <span>{isOpened ? text : text.slice(0, limit - 10)}</span>
      {showBtn && (
        <span
          className="turnCateBtn"
          style={{ cursor: "pointer", display: "block" }}
          onClick={toggleOpenState}
        >
          {isOpened ? (
            // "---- view-less"
            // <img className="icon" src="icons/less.svg" alt="" srcSet="" />
            <MdExpandLess
              size={"1.2em"}
              color={"black"}
              className="expandIcon expandLess"
            />
          ) : (
            // "---- view-more"
            // <img className="icon" src="icons/more.svg" alt="" srcSet="" />
            <MdExpandMore
              size={"1.2em"}
              color={"black"}
              className="expandIcon expandMore"
            />
          )}
        </span>
      )}
    </>
  );
}
