import React, { useState, useRef, useContext } from "react";
import { signInWithGoogle } from "../../../firebase";
import { validateUID, validateUnlockKey } from "../../../validateUser";
import { MdAccountCircle, MdLock, MdVpnKey } from "react-icons/md";
import "./loginPage_Styles.scss";
import { auth } from "../../../firebase";
import { AuthContext } from "../../../AuthContext";

function LoginPage({ history }) {
  const { setCurrentUser } = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState({});
  const [locked, setLocked] = useState(true);
  const keyInput = useRef(null);

  function login() {
    signInWithGoogle()
      .then((data) => {
        if (validateUID(data.user.uid)) {
          setCurrentUser(data.user.uid);
          history.push("/");
        } else {
          setErrorMessage({
            message: `Muggles are not allowed to enter.`,
            error: "UNAUTHORIZED",
          });
          auth.signOut();
        }
      })
      .catch((error) => {
        setErrorMessage({ message: error.toString() });
      });
  }

  function unlock(e) {
    e.preventDefault();
    setErrorMessage(``);
    if (validateUnlockKey(keyInput.current.value)) {
      setLocked(false);
    } else {
      setErrorMessage({ message: "Fake Golden Key" });
    }
  }

  return (
    <main id="loginPage">
      {locked ? (
        <div>
          <div className="pageTitle">
            <MdLock style={{ verticalAlign: "middle" }} size={"3em"} />
            <h1> Locked</h1>
          </div>

          <form onSubmit={unlock}>
            <label className="formLabel" htmlFor="keyInput">
              Please put the
              <span className="securityKeyBlock">Golden Key</span>
              to proceed further.
            </label>

            {errorMessage.message ? (
              <>
                <div className="gifImage">
                  <img src="gifs/403.gif" alt="" srcSet="" />
                </div>
                <h3 className="errorMessage">{errorMessage.message}</h3>
              </>
            ) : (
              <div className="gifImage keyGif">
                <img src="gifs/key.gif" alt="" srcSet="" />
              </div>
            )}
            <div className="iconInput">
              <div className="inputIconBox">
                <MdVpnKey style={{ verticalAlign: "middle" }} size={"1.5em"} />
              </div>
              <input
                ref={keyInput}
                className="formInput"
                id="keyInput"
                type="password"
                autoComplete="none"
                placeholder="Golden KEY"
                required
              />
            </div>
            <button id="unlockBtn" className="button primary-button ">
              Alohomora
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className="pageTitle">
            <MdAccountCircle style={{ verticalAlign: "middle" }} size={"3em"} />
            <h1> Login</h1>
          </div>

          {errorMessage.message ? (
            <>
              <div className="gifImage">
                {errorMessage.error === "UNAUTHORIZED" ? (
                  <img src="gifs/unAuthorized.gif" alt="" srcSet="" />
                ) : (
                  <img src="gifs/otherError.gif" alt="" srcSet="" />
                )}
              </div>
              <h3 className="errorMessage">{errorMessage.message}</h3>
            </>
          ) : (
            <div className="gifImage">
              <img src="gifs/areYouSure.gif" alt="" srcSet="" />
            </div>
          )}
          <img
            id="signInBtn"
            onClick={login}
            src="icons/googleSignInBtn.png"
            alt=""
            srcSet=""
          />
        </div>
      )}
    </main>
  );
}

export default LoginPage;
