import React, { useState } from "react";
import { colorize } from "../colorize";

import TimeAgo from "timeago-react";

import Turncater from "../turncater/turncater-component";
import "./messageBox_styles.scss";

function MessageBox({ senderName, senderEmail, time, msg, status }) {
  // component states
  const [readStatus, setreadStatus] = useState(status);
  const [showRelativeTime, setshowRelativeTime] = useState(true);
  // helper functions
  const getRelativeTime = () => {
    // return timeago.format(new Date(time));
    return <TimeAgo live={false} datetime={time} locale="en_IN" />;
  };
  const getAbsoluteTime = () => {
    return new Date(time).toLocaleString("en-IN", {
      timeZoneName: "short",
    });
  };
  // event handlers
  function toggleTimeType() {
    setshowRelativeTime((prevValue) => {
      return !prevValue;
    });
  }

  function toggleReadStatus() {
    setreadStatus((prevState) => {
      return !prevState;
    });
  }

  return (
    <div className={`MessageBox ${readStatus ? "read" : "unread"}`}>
      <div
        onDoubleClick={toggleReadStatus}
        className="avatar"
        style={{ backgroundColor: colorize(senderEmail) }}
      >
        {senderName[0].toUpperCase()}
      </div>
      <div className="details">
        <div className="detailsHeader">
          <h2 className="senderName">{senderName}</h2>
          <span onDoubleClick={toggleTimeType} className="dateTime">
            {" "}
            {showRelativeTime ? getRelativeTime() : getAbsoluteTime()}
          </span>
        </div>
        <a className="senderEmail" href={`mailto:${senderEmail}`}>
          {senderEmail}
        </a>
        <Turncater text={msg} limit="100" />
      </div>
    </div>
  );
}

export default MessageBox;
