import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// component

// context : imports
import { AuthProvider } from "./AuthContext";
// High Order Router
import ProtectedRoute from "./ProtectedRoute";
// Pages
import HomePage from "./components/Pages/Homepage/HomePage";
import LoginPage from "./components/Pages/LoginPage/LoginPage";
// main component
function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <ProtectedRoute exact path="/" component={HomePage} />
          <Route exact path="/login" component={LoginPage} />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
